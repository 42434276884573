/* eslint-disable */
const INIT_CONFIG = { weightX: .7, weightY: -1, bias: 10 , learningRate: .1 };
const INIT_INPUTS: RblattInput[] = [
    [ 2.101231155778894, 4.947319932998326, 0 ],
    [ 8.27, -3.94, 0 ],
    [ 0.7838107202680059, 4.2886097152428815, 0 ],
    [ 2.7711055276381822, 9.000921273031828, 0 ],
    [ 5.344112227805695, 5.910050251256282, 0 ],
    [ 5.445452261306533, 1.1977386934673367, 0 ],
    [ 4.482721943048576, -0.7783919597989952, 0 ],
    [ 7.725603015075377, -3.05854271356784, 0 ],
    [ 5.445452261306533, -3.210552763819096, 0 ],
    [ -0.28025963149078823, 2.0084589614740372, 1 ],
    [ 2.506591289782244, -3.6159128978224464, 1 ],
    [ -2.2057202680067015, -0.4237018425460638, 1 ],
    [ -3.573810720268008, 2.819179229480737, 1 ],
    [ -5.549941373534341, 2.211139028475712, 1 ],
    [ 0.5304606365159121, -3.109212730318259, 1 ],
    [ -4.485871021775546, -3.1598827470686774, 1 ]
];
const CLEARED_INPUTS: RblattInput[] = [];

export type RblattInput = [ number, number, 0 | 1 ];

export type RblattConfig = { weightX: number, weightY: number, bias: number, learningRate: number };

export {INIT_CONFIG, INIT_INPUTS, CLEARED_INPUTS};
